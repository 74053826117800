import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'
import NumberCounter from 'number-counter'
import { motion } from 'framer-motion'


const Hero = () => {
    const transition = { type: 'spring', duration: 3 }
   const mobile=window.innerWidth <= 768 ? true:false;
    return (
        <div className="hero" id='home'>
            <div className="blur hero-blur"></div>
            {/*heor left side*/}
            <div className="l-hero">
                <Header />

                {/*the best ad*/}
                <div className="the-best-ad">
                    <motion.div
                        initial={{ left: mobile?"160px":'238px' }}
                        whileInView={{ left: '8px' }}
                        transition={{ ...transition, type: 'tween' }}>

                    </motion.div>
                    <span>the best fitness club in the town</span>
                </div>

                {/*Hero Heading*/}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>

                    <div>
                        <span>Ideal Body</span>
                    </div>
                    <div >
                        <span>
                            In here we help and guide you to shape , build and develop your ideas
                            live up your life to fullest
                        </span>
                    </div>
                </div>

                {/*figures*/}
                <div className="figures">
                    <div>
                        <span><NumberCounter end={140} start={100} delay='4' preFix='+'/></span>
                        <span>fitness programs</span>
                    </div>
                    <div>
                        <span><NumberCounter end={978} start={900} delay='4' preFix='+'/></span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span><NumberCounter end={50} start={10} delay='3' preFix='+'/></span>
                        <span>members joined</span>
                    </div>
                </div>

                {/*heor buttons*/}
                <div className="hero-buttons">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>
            </div>

            {/*heor right side*/}
            <div className="r-hero">
                <button className="btn">Join Now</button>

                <motion.div
                    initial={{ right: '-1rem' }}
                    whileInView={{ right: '4rem' }}
                    transition={{ ...transition, type: 'tween' }}
                    className="heart-rate">
                    <img src={heart} alt="" />
                    <span>Heart Rate</span><span>116 bpm</span>
                </motion.div>

                {/*hero images*/}
                <motion.img
                    initial={{ top: '5rem' }}
                    whileInView={{ top: '10rem' }}
                    transition={{ ...transition, type: 'tween' }}
                    src={hero_image} alt="" className='hero-image' />
                <motion.img
                    initial={{ right: '11rem' }}
                    whileInView={{ right: '20rem' }}
                    transition={{ ...transition, type: 'tween' }}
                    src={hero_image_back} alt="" className='hero-image-back' />

                {/*hero calories*/}
                <motion.div
                    key={origin}
                    initial={{ right: '37rem' }}
                    whileInView={{ right: '28rem' }}
                    transition={{ ...transition, type: 'tween' }}
                    className="calories">
                    <img src={calories} alt="" />
                    <div> <span>Calories Burned</span>
                        <span>220 kcal</span></div>

                </motion.div>
            </div>
        </div>
    )
}

export default Hero