import React from 'react'
import './Program.css'
import { programsData } from '../../data/programsData'
import RightArrows from '../../assets/rightArrow.png'
const Program = () => {
    return (
        <div className="Programs" id="programs">
            {/*Program Header*/}
            <div className="programs-header">
                <span className='stroke-text'>Explore our</span>
                <span>Programs</span>
                <span className='stroke-text'>to shape you</span>

            </div>
            <div className="programs-categories">
                {programsData.map((program , i) => (
                    <div className="category" key={i}>
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                        <div className="join-now">
                            <span>Join Now</span>
                            <img src={RightArrows} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Program