import React, { useState } from 'react'
import './Testimonials.css'
import { testimonialsData } from '../../data/testimonialsData'
import leftArrows from '../../assets/leftArrow.png'
import rightArrows from '../../assets/rightArrow.png'
import { motion } from 'framer-motion'
const Testimonials = () => {

    const [selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;

    const transition = { type: 'spring', duration: 3 }
    return (
        <div className="testimonials">
            <div className="l-testimonial">
                <span>Testimonials</span>
                <span className='stroke-text'>What they</span>
                <span>say about us</span>
                <motion.span
                    key={selected}
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}
                    exit={{ opacity: 0, x: -100 }}
                >
                    {testimonialsData[selected].review}</motion.span>
                <span>
                    <span style={{ color: 'var(--orange)' }}>
                        {testimonialsData[selected].name}
                    </span>{" "}
                    - {testimonialsData[selected].status}
                </span>
            </div>
            <div className="r-testimonial">
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}
                ></motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}
                ></motion.div>
                <motion.img
                    key={selected}
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}
                    exit={{ opacity: 0, x: -100 }}
                    src={testimonialsData[selected].image} alt="" />
                <div className="arrows">
                    <img
                        onClick={() => {
                            selected === 0 ? setSelected(tLength - 1)
                                : setSelected((prev) => prev - 1)
                        }} src={leftArrows} alt="" />
                    <img onClick={() => {
                        selected === tLength - 1 ? setSelected(0) : setSelected((next) => next + 1)
                    }} src={rightArrows} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Testimonials