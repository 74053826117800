import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
const Join = () => {
    const form=useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_aq4litt', 'template_r6evmdd', form.current, 'jrXtbZWx533KX9E1e')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    return (
        <div className="Join" id="Join">
            <div className="l-join">
                <hr />
                <div>
                    <span className='stroke-text'>READY TO </span>
                    <span> LEVEL UP </span>
                </div>
                <div>
                    <span>YOUR BOUDY </span>
                    <span className='stroke-text'> WITH US?</span>
                </div>
            </div>
            <div className="r-join">
                <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type="email" name='user-email' placeholder='Enter your email address'/>
                    <button  className="btn btn-j">Join Now</button>
                </form>

            </div>
        </div>
    )
}

export default Join